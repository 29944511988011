import React, { Component } from "react";
import { addDays, differenceInCalendarDays, format } from "date-fns";

import B2BWidgets from "../../../b2bwidgets/index";
import DesktopCalender from "../../../calendar/hotelCalender/theme5/DesktopCalender";
import HotelAutoComplete from "./HotelAutoComplete";
import NationalityDropdownComp from "../../../NationalityDropDown/index";
import cf from "../../../../lib/utils/common";
import getNationalitiesList from "../../../../lib/getNationalitiesList";
import MobPopupComponent from "../../../ModalPopupComponent/theme5mobile/ModalPopupComponent";
import MobileCalender from "../../../calendar/hotelCalender/theme5/MobileCalender";
import commonFunction from "../../../../lib/utils/common";
import { getRecentSearchHotel } from "../../../../lib/getRecentSearchHotel";
import { getStorage, setStorage } from "../../../../lib/utils/localStorage";
import Button from "../../../UI/button/index.js";
const childAgeList = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
];
let regions = "";
let indexDropdown = -1;
let search_text = "";
let destination_id = "";
let search_type = "";
export default class Theme2 extends Component {
  constructor(props) {
    super(props);
    this.setScrollDown = React.createRef();
    this.wrapperRef = React.createRef();
    this.advanceSearchWrapper = React.createRef();
    this.guestDetailWrapper = React.createRef();
    this.state = {
      hotelName: "",
      expandHotelNameBoolean: false,
      checkInOutValue: "",
      checkInValue: cf.getDateWithFormat(new Date(), this?.props?.dateFormat),
      checkOutValue: cf.getDateWithFormat(
        addDays(
          new Date(),
          this.props.getDefaultDays ? this.props.getDefaultDays : 2
        ),
        this?.props?.dateFormat
      ),
      dateLimit: cf.getDateWithFormat(new Date(), this?.props?.dateFormat),
      openRoomDetailList: false,
      expandCheckInOutDateBoolean: false,
      selectedRoomDetails: { rooms: 1, guest: 2 },
      getAutoSuggestList: this.props.getAutoSuggestList,
      isError: {
        hotelName: false,
        checkInOutDate: false,
        roomGuest: false,
        selectedNationality: false,
        selectedGuest: false,
      },
      getDestinationId: "",
      getSearchType: "",
      property_count: "",
      dest_type: {
        code: "",
        name: "",
      },
      roomList: [
        {
          id: 1,
          adults: 2,
          children: 0,
          adultAddCountDisable: false,
          adultMinusCountDisable: false,
          childrenAddCountDisable: false,
          childrenMinusCountDisable: true,
          childDetails: [],
        },
      ],
      // finalRoomList: [{ id: 1, adults: 2, children: 0, childDetails: [] }],
      finalRoomList: [
        {
          id: 1,
          adults: 2,
          children: 0,
          adultAddCountDisable: false,
          adultMinusCountDisable: true,
          childrenAddCountDisable: false,
          childrenMinusCountDisable: true,
          childDetails: [],
        },
      ],
      type: "hotel",
      openTraveler: false,
      advSearch: false,
      nationallitySearch: null,
      is_breakfast: "",
      // isBreakfastChecked: false,
      originCountries: [],
      selectedNationality: "",
      response: [],
      isOneStar: false,
      isTwoStar: false,
      isThreeStar: false,
      isFourStar: false,
      isFiveStar: false,
      selectedCheckBoxes: {},
      guest_id: "",
      openSearch: false,
      destination: false,
      calender: false,
      rooms: false,
      selectedDestination: null,
      getrecentSearchList: null,
      getrecentSearchListLocal: null,
      search_key: "hotelSearchKey",
      search_key_all: "hotelSearchKeyAll",
      isKeyDown: false,
      isSearchDest: false,
      btnClicked: false,
    };
  }

  // Nationality

  componentDidMount() {
    // Local storage recent search ========>

    if (true) {
      let LocalSearch = getStorage(this.state.search_key);

      let LocalSearchAll = getStorage(this.state.search_key_all);

      if (LocalSearchAll != null) {
        this.setState({ getrecentSearchListLocal: JSON.parse(LocalSearchAll) });
      }
      if (LocalSearch != null && LocalSearchAll == null) {
        this.setState({ getrecentSearchListLocal: [JSON.parse(LocalSearch)] });
      }
    }

    // Nationality
    getNationalitiesList().then((resp) => {
      let nationalityList = [];
      if (resp?.data?.data.length > 0) {
        resp?.data.data.map((item) => {
          nationalityList.push({
            ...item,
            value: item.nationality_name,
            countryCode: item.nationality_code,
          });
        });
      }
      this.setState({ originCountries: nationalityList });
    });

    regions = new URLSearchParams(window.location.search).get("region");
    if (
      !cf.isValueEmpty(this.props) &&
      (this.props.isSRP === true || this.props.isDetail === true) &&
      !cf.isValueEmpty(this.props.query)
    ) {
      let checkInArr = this.props.query.checkin.split("-");
      let checkOutArr = this.props.query.checkout.split("-");
      let checkInValue = cf.getDateWithFormat(
        new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]),
        this?.props?.dateFormat
      );
      let checkOutValue = cf.getDateWithFormat(
        new Date(checkOutArr[0], Number(checkOutArr[1]) - 1, checkOutArr[2]),
        this?.props?.dateFormat
      );
      let checkInOut =
        cf.getDateWithFormat(
          new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]),
          this?.props?.dateFormat
        ) +
        " - " +
        cf.getDateWithFormat(
          new Date(checkOutArr[0], Number(checkOutArr[1]) - 1, checkOutArr[2]),
          this?.props?.dateFormat
        );
      let roomsdetail = this.props.query.rooms.split("_");
      let totalRooms = roomsdetail.length;
      let totalAdult = 0;
      let totalChildren = 0;
      let roomListArr = [];
      let finalRoomListArr = []; //new
      roomsdetail.map((ele, index) => {
        let split = ele.split("-").filter((item) => item);
        totalAdult = Number(split[0].charAt(0)) + totalAdult;
        totalChildren = Number(split[1].charAt(0)) + totalChildren;
        roomListArr = [
          ...roomListArr,
          {
            id: index + 1,
            adults: Number(split[0].charAt(0)),
            children: Number(split[1].charAt(0)),
            adultAddCountDisable: false,
            adultMinusCountDisable: true,
            childrenAddCountDisable: false,
            childrenMinusCountDisable:
              Number(split[1].charAt(0)) == 1 ? false : true,
            childDetails: [],
          },
        ];

        split.map((element, id) => {
          if (id > 1)
            roomListArr[index].childDetails = [
              ...roomListArr[index].childDetails,
              { age: element, isOpen: false, isError: false },
            ];
        });
      });
      this.setState(
        {
          checkInOutValue: checkInOut,
          checkInValue: checkInValue,
          checkOutValue: checkOutValue,
          getDestinationId: this.props.query.destid,
          getSearchType: this.props.query.search_type,
          hotelName: this.props.AutoSuggestLabelName
            ? this.props.AutoSuggestLabelName
            : this?.props?.query?.region + ", " + this?.props?.query?.country,
          selectedRoomDetails: {
            rooms: totalRooms,
            guest: totalAdult + totalChildren,
          },
          roomList: roomListArr,
          nationallitySearch: this.props.query.nationality,
          // response:this.props.query.ratings,
          guest_id: this.props.query.guestId,
        },
        // this.setState(prevState => ({
        //   ...prevState.selectedCheckBoxes,
        //   selectedCheckBoxes: {
        //       breakfast:[[this.props.query.bf]],
        //       room_type:[[this.props.query.rf]],
        //   }
        // })),
        () => {
          this.checkRoomDetails();
        }
      );
      const deepClone = JSON.parse(JSON.stringify(roomListArr));
      this.setState({
        finalRoomList: deepClone,
      });
    } else {
      if (regions) {
        this.renderInfo();
        // Detail Page Conditions
        if (this.props.isDetail) {
          let checkInArr = this.props.query.checkin.split("-");
          let checkOutArr = this.props.query.checkout.split("-");
          let checkInValue = cf.getDateWithFormat(
            new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]),
            this?.props?.dateFormat
          );
          let checkOutValue = cf.getDateWithFormat(
            new Date(
              checkOutArr[0],
              Number(checkOutArr[1]) - 1,
              checkOutArr[2]
            ),
            this?.props?.dateFormat
          );
          // console.log("true", checkOutValue, checkInValue);
          let checkInOut =
            cf.getDateWithFormat(
              new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]),
              this?.props?.dateFormat
            ) +
            " - " +
            cf.getDateWithFormat(
              new Date(
                checkOutArr[0],
                Number(checkOutArr[1]) - 1,
                checkOutArr[2]
              ),
              this?.props?.dateFormat
            );
          // console.log("true", checkInOut);
          let roomsdetail = this.props.query.rooms.split("_");
          let totalRooms = roomsdetail.length;
          let totalAdult = 0;
          let totalChildren = 0;
          let roomListArr = [];
          let finalRoomListArr = []; //new
          roomsdetail.map((ele, index) => {
            let split = ele.split("-").filter((item) => item);
            totalAdult = Number(split[0].charAt(0)) + totalAdult;
            totalChildren = Number(split[1].charAt(0)) + totalChildren;
            roomListArr = [
              ...roomListArr,
              {
                id: index + 1,
                adults: Number(split[0].charAt(0)),
                children: Number(split[1].charAt(0)),
                adultAddCountDisable: false,
                adultMinusCountDisable: true,
                childrenAddCountDisable: false,
                childrenMinusCountDisable:
                  Number(split[1].charAt(0)) == 1 ? false : true,
                childDetails: [],
              },
            ];

            split.map((element, id) => {
              if (id > 1)
                roomListArr[index].childDetails = [
                  ...roomListArr[index].childDetails,
                  { age: element, isOpen: false, isError: false },
                ];
            });
          });
          // console.log(checkInOut, checkInValue, checkOutValue);
          this.setState({
            checkInOutValue: checkInOut,
            checkInValue: checkInValue,
            checkOutValue: checkOutValue,
            hotelName:
              this?.props?.query?.region + ", " + this?.props?.query?.country,
            getDestinationId: this?.props?.query?.destid,
            getSearchType: this?.props?.query?.search_type,
            selectedRoomDetails: {
              rooms: totalRooms,
              guest: totalAdult + totalChildren,
            },
            roomList: roomListArr,
            vid: this.props?.query?.vid,
            sid: this.props?.query?.sid,
          });
        }
      }
      if (!this.props.isDetail) {
        this.setState({
          checkInOutValue: this.getCheckInOutValue(
            this.state.checkInValue,
            this.state.checkOutValue
          ),
        });
      }
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    // document.addEventListener('mousedown', function(e){ e.preventDefault(); }, false)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  checkRoomDetails() {
    let { roomList } = this.state;
    roomList.map((ele, index) => {
      let totalAccomodation = ele.adults + ele.children;
      if (totalAccomodation <= 12) {
        if (totalAccomodation === 12) {
          roomList[index].adultAddCountDisable = true;
          roomList[index].childrenAddCountDisable = true;
        } else if (totalAccomodation < 12) {
          if (
            roomList[index].adultAddCountDisable === true &&
            roomList[index].childrenAddCountDisable === true
          ) {
            roomList[index].adultAddCountDisable = false;
            roomList[index].childrenAddCountDisable = false;
          }
          if (roomList[index].adults > 1) {
            roomList[index].adultMinusCountDisable = false;
          } else if (roomList[index].adults === 1) {
            roomList[index].adultMinusCountDisable = true;
            roomList[index].adultAddCountDisable = false;
          }
          if (roomList[index].children > 1) {
            roomList[index].childrenMinusCountDisable = false;
          } else if (roomList[index].children > 0) {
            roomList[index].childrenMinusCountDisable = false;
            roomList[index].childrenAddCountDisable = false;
          }
        }
      }
    });
    this.setState({ roomList: [...roomList] });
  }
  handleClickOutside = (e) => {
    if (
      this.dropdownWrapperRef &&
      !this.dropdownWrapperRef.contains(e.target)
    ) {
      if (this.props.expandHotelNameBoolean === true)
        this.props.expandHotelNameBooleanfunc(false);
      if (
        this.advanceSearchWrapper.current &&
        !this.advanceSearchWrapper.current.contains(event.target)
      ) {
        this.setState({ advSearch: false });
      }
      if (
        this.guestDetailWrapper.current &&
        !this.guestDetailWrapper.current.contains(event.target)
      ) {
        this.setState({ openTraveler: false });
      }
      if (
        this.state.expandCheckInOutDateBoolean === true ||
        this.state.openRoomDetailList === true
      ) {
        this.setState({
          expandCheckInOutDateBoolean: false,
          openRoomDetailList: false,
        });
      }
    }
  };
  setDropdownWrapperRef = (node) => {
    this.dropdownWrapperRef = node;
  };
  onClickDoneButton = () => {
    let totalGuest = 0;
    let count = 0;
    let Validation = true;
    let validation_arr = [];
    let roomList = this.state.roomList;
    // let finalRoomListArr = []; //new
    while (count <= roomList.length) {
      if (
        !cf.isValueEmpty(roomList[count]) &&
        !cf.isValueEmpty(roomList[count].childDetails) &&
        roomList[count].childDetails.length > 0
      ) {
        // finalRoomListArr = [...finalRoomListArr, { id: count + 1, adults: roomList[count].adults, children: roomList[count].children, childDetails: [{ age: '' }] }] //new
        roomList[count].childDetails.map((element, index) => {
          if (cf.isValueEmpty(element.age)) {
            roomList[count].childDetails[index].isError = true;
          }
        });
        // Validation = roomList[count].childDetails.every(item => !cf.isValueEmpty(item.age));
        validation_arr.push(
          roomList[count].childDetails.every(
            (item) => !cf.isValueEmpty(item.age)
          )
        );
        let checker = validation_arr.every(Boolean);
        Validation = checker;
      }
      count++;
    }
    // this.setState({ roomList: [...roomList] })
    this.setState({ roomList: [...roomList] }, () => {
      if (Validation === true) {
        this.state.roomList.length > 0 &&
          this.state.roomList.map((element) => {
            totalGuest = totalGuest + element.adults + element.children;
            //new
            // element.childDetails.map((ele, i) => {
            //     finalRoomListArr[idx].childDetails[i].age = ele.age;
            // })
          });

        this.setState({
          // finalRoomList: finalRoomListArr,
          selectedRoomDetails: {
            rooms: this.state.roomList.length,
            guest: totalGuest,
          },
        });
        const deepClone = JSON.parse(JSON.stringify(roomList));
        this.setState({
          finalRoomList: deepClone,
        });
        this.setState({ rooms: false });
        document.querySelector("body").style.overflow = "auto";
        if (this.state.openRoomDetailList === true) {
          this.setState({ openRoomDetailList: false });
        }
      }
    });
  };
  removeRoomItem = (id) => {
    const filteredRoom = this.state.roomList.filter((item) => item.id !== id);
    this.setState({ roomList: [...filteredRoom] });
  };

  addAccomodationCount = async (id, index, type) => {
    let { roomList } = this.state;
    if (type === "adult") {
      if (roomList[index].id === id) {
        roomList[index].adults = roomList[index].adults + 1;
      }
    } else {
      if (roomList[index].id === id) {
        roomList[index].children = roomList[index].children + 1;
        roomList[index].childDetails = [
          ...roomList[index].childDetails,
          { age: "", isOpen: false, isError: false },
        ];
      }
    }
    let totalAccomodation = roomList[index].adults + roomList[index].children;
    if (totalAccomodation <= 7) {
      if (totalAccomodation === 7) {
        roomList[index].adultAddCountDisable = true;
        roomList[index].childrenAddCountDisable = true;
      } else if (totalAccomodation < 7) {
        if (
          roomList[index].adultAddCountDisable === true &&
          roomList[index].childrenAddCountDisable === true
        ) {
          roomList[index].adultAddCountDisable = false;
          roomList[index].childrenAddCountDisable = false;
        }
      }
      if (roomList[index].adults > 1) {
        roomList[index].adultMinusCountDisable = false;
      }
      if (roomList[index].children > 0) {
        roomList[index].childrenMinusCountDisable = false;
      }
      await this.setState({ roomList: [...roomList] });
    }
  };
  minusAccomodationCount = async (id, index, type) => {
    let { roomList } = this.state;
    if (type === "adult") {
      if (roomList[index].id === id) {
        if (roomList[index].adults >= 2) {
          roomList[index].adults = roomList[index].adults - 1;
          roomList[index].adultMinusCountDisable = false;
          if (roomList[index].adultAddCountDisable === true) {
            roomList[index].adultAddCountDisable = false;
          }
          if (roomList[index].childrenAddCountDisable === true) {
            roomList[index].childrenAddCountDisable = false;
          }
        } else if (roomList[index].adults === 1) {
          roomList[index].adultMinusCountDisable = true;
        }
      }
    } else {
      if (roomList[index].id === id) {
        roomList[index].childDetails.pop();
        if (roomList[index].children >= 1) {
          roomList[index].children = roomList[index].children - 1;
          roomList[index].childrenMinusCountDisable = false;
          if (roomList[index].childrenAddCountDisable === true) {
            roomList[index].childrenAddCountDisable = false;
          }
          if (roomList[index].adultAddCountDisable === true) {
            roomList[index].adultAddCountDisable = false;
          }
        } else if (roomList[index].children === 0) {
          roomList[index].childrenMinusCountDisable = true;
        }
      }
    }
    await this.setState({ roomList: [...roomList] });
  };
  isChildrenAge = (index, ind, type, age) => {
    let { roomList } = this.state;
    if (type === "isOpen") {
      roomList[index].childDetails[ind].isOpen =
        !roomList[index].childDetails[ind].isOpen;
    } else {
      roomList[index].childDetails[ind].age = age;
      roomList[index].childDetails[ind].isOpen = false;
      if (roomList[index].childDetails[ind].isError === true) {
        roomList[index].childDetails[ind].isError = false;
      }
    }
    this.setState({ roomList: [...roomList] });
  };
  onChangeCheckInOutValue = (value, type) => {
    if (type === "startDate") {
      this.setState({ checkInValue: value });
    } else if (type === "endDate") {
      let differenceInDays = differenceInCalendarDays(
        new Date(value),
        new Date(this.state.checkInValue)
      );
      if (differenceInDays === 0) {
        this.setState({ checkOutValue: addDays(new Date(value), 1) }, () => {
          if (!cf.isValueEmpty(value)) {
            let checkInOut =
              cf.getDateWithFormat(
                this?.state?.checkInValue,
                this?.props?.dateFormat
              ) +
              " - " +
              cf.getDateWithFormat(
                this?.state?.checkOutValue,
                this?.props?.dateFormat
              );
            this.setState({ checkInOutValue: checkInOut });
          }
        });
      } else {
        this.setState({ checkOutValue: value }, () => {
          if (!cf.isValueEmpty(value)) {
            let checkInOut =
              cf.getDateWithFormat(
                this?.state?.checkInValue,
                this?.props?.dateFormat
              ) +
              " - " +
              cf.getDateWithFormat(value, this?.props?.dateFormat);
            this.setState({ checkInOutValue: checkInOut });
          }
        });
      }
    }
  };

  onChangeSetHotelName = (value) => {
    this.setState({ hotelName: value, getDestinationId: "" }, () => {
      if (this.state.isError.hotelName === true) {
        this.validateErrorMsg();
      }
      if (value.length > 2) {
        this.setState({ isSearchDest: true });
        this.props.getAutoSuggestValue(value);
      }
    });
  };
  validateErrorMsg = () => {
    const mapDispatch = {
      hotelName: cf.isValueEmpty(this.state.hotelName) ? true : false,
      checkInOutDate: cf.isValueEmpty(this.state.checkInOutValue)
        ? true
        : false,
      roomGuest: cf.isValueEmpty(this.state.selectedRoomDetails) ? true : false,
      selectedNationality: cf.isValueEmpty(this.state.selectedNationality)
        ? true
        : false,
      selectedGuest: cf.isValueEmpty(this.state.guest_id) ? true : false,
    };
    this.setState({ isError: mapDispatch });
  };

  onClickSearchHotel = () => {
    // Local storage recent search ========>

    if (
      this.state.hotelName &&
      this.props?.userProfile &&
      this.props?.userProfile?.status == false
    ) {
      let LocalSearch = getStorage(this.state.search_key);
      let LocalSearchAll = JSON.parse(getStorage(this.state.search_key_all));
      const searchItem = JSON.parse(LocalSearch);

      if (LocalSearch) {
        if (LocalSearchAll == null) {
          var searchData = getStorage(this.state.search_key);
          var searchDataArr = [JSON.parse(searchData)];

          let myObject = {
            destination_id: this.state.getDestinationId,
            search_type: this.state.getSearchType,
            search_text: this.state.hotelName,
            property_count: this.state.property_count,
            dest_type: {
              code: this.state.dest_type?.code || "",
              text: this.state.dest_type?.text || "",
            },
          };
          // searchDataArr.push(myObject); // add the object to the array
          if (searchItem.destination_id !== myObject.destination_id) {
            searchDataArr.unshift(myObject);
            var searchDataStrig = JSON.stringify(searchDataArr);
            setStorage(this.state.search_key, searchDataStrig);
            setStorage(
              this.state.search_key_all,
              JSON.stringify(searchDataArr)
            );
          }
        }

        if (LocalSearchAll) {
          var searchData = getStorage(this.state.search_key);
          var searchDataAll = JSON.parse(getStorage(this.state.search_key_all));
          var searchDataArr = [JSON.parse(searchData)];
          var indexArr = [];
          searchDataAll.forEach((x, i) => {
            indexArr.push(x.destination_id);
          });

          let myObject = {
            destination_id: this.state.getDestinationId,
            search_type: this.state.getSearchType,
            search_text: this.state.hotelName,
            property_count: this.state.property_count,
            dest_type: {
              code: this.state.dest_type?.code || "",
              text: this.state.dest_type?.text || "",
            },
          };

          if (!indexArr.includes(myObject.destination_id)) {
            searchDataAll.unshift(myObject);
            var searchDataStrig = JSON.stringify(searchDataArr);
            setStorage(
              this.state.search_key_all,
              JSON.stringify(searchDataAll)
            );
          }

          if (searchDataAll.length > 5) {
            searchDataAll.pop();
            setStorage(
              this.state.search_key_all,
              JSON.stringify(searchDataAll)
            );
          }
        }
      } else {
        var searchData = [];
        let data = {
          destination_id: this.state.getDestinationId,
          search_type: this.state.getSearchType,
          search_text: this.state.hotelName,
          property_count: this.state.property_count,
          dest_type: {
            code: this.state.dest_type?.code || "",
            text: this.state.dest_type?.text || "",
          },
        };
        // searchData.push(data); // add the object to the array
        searchData.unshift(data);
        var searchDartaStrig = JSON.stringify(searchData);
        setStorage(this.state.search_key, searchDartaStrig);
      }

      let data = {
        destination_id: this.state.getDestinationId,
        search_type: this.state.getSearchType,
        search_text: this.state.hotelName,
        property_count: this.state.property_count,
        dest_type: {
          code: this.state.dest_type?.code || "",
          text: this.state.dest_type?.text || "",
        },
      };

      setStorage(this.state.search_key, JSON.stringify(data));
    }

    // main function =====>

    const mapDispatch = {
      hotelName: cf.isValueEmpty(this.state.hotelName) ? true : false,
      checkInOutDate: cf.isValueEmpty(this.state.checkInOutValue)
        ? true
        : false,
      roomGuest: cf.isValueEmpty(this.state.selectedRoomDetails) ? true : false,
      selectedNationality: cf.isValueEmpty(this.state.selectedNationality)
        ? true
        : false,
      selectedGuest: cf.isValueEmpty(this.state.guest_id) ? true : false,
    };
    if (!this.props?.userProfile?.type) {
      mapDispatch.selectedNationality = cf.isValueEmpty(
        this.state.selectedNationality
      )
        ? false
        : true;
    }
    if (this.props?.userProfile?.type !== "B2B") {
      mapDispatch.selectedNationality = cf.isValueEmpty(
        this.state.selectedNationality
      )
        ? false
        : true;
    }
    if (this.props.isOffline !== true) {
      mapDispatch.selectedGuest = cf.isValueEmpty(this.state.selectedGuest)
        ? false
        : true;
    }
    if (!this.props.isOffline) {
      mapDispatch.selectedGuest = cf.isValueEmpty(this.state.selectedGuest)
        ? false
        : true;
    }
    if (this.props.isSRP === true) {
      mapDispatch.selectedNationality = cf.isValueEmpty(
        this.state.selectedNationality
      )
        ? false
        : true;
    }
    if (this.props.isDetail == true) {
      mapDispatch.hotelName = cf.isValueEmpty(regions) ? true : false;
    }
    mapDispatch.hotelName = this.state.getDestinationId ? false : true;
    this.setState({ isError: mapDispatch }, () => {
      let Validate = true;
      for (const [key, value] of Object.entries(this.state.isError)) {
        if (value === true) {
          Validate = false;
          break;
        }
      }
      if (Validate === true) {
        let hotelNameArry = this.state.hotelName.split(",");
        let roomListArr = [];
        this.state.finalRoomList.map((res, id) => {
          roomListArr = [
            ...roomListArr,
            res.adults + "a-" + res.children + "c",
          ];
          res.childDetails.map((ele) => {
            roomListArr[id] = roomListArr[id].concat("-", ele.age);
          });
        });
        //IF CHECKOUT DATE IS EMPTY
        if (!this.state.checkOutValue) {
          this.state.checkOutValue = cf.getDateWithFormat(
            addDays(new Date(this.state.checkInValue), 2),
            this?.props?.dateFormat
          );
        }
        let starb2bFilter = [...new Set(this.state.response)];
        let urlQuery = {
          region: hotelNameArry[0]?.trim(),
          country:
            this.props.isSRP === true
              ? hotelNameArry[1]?.trim()
              : hotelNameArry[hotelNameArry.length - 1]?.trim(),
          checkin: format(new Date(this.state.checkInValue), "yyyy-MM-dd"),
          checkout: format(new Date(this.state.checkOutValue), "yyyy-MM-dd"),
          rooms: roomListArr.join("_"),
          type: "location",
          roomtype: "single",
          page: 1,
          orderby: "desc",
          mop: "accrual",
          destid: this.state.getDestinationId,
          search_type: this.state.getSearchType,
          ratings: starb2bFilter.join("_"),
          bf: this.state.selectedCheckBoxes?.breakfast?.[0]
            ? this.state.selectedCheckBoxes?.breakfast?.[0]
            : null,
          rf: this.state.selectedCheckBoxes?.room_type?.[0]
            ? this.state.selectedCheckBoxes?.room_type?.[0]
            : null,
          nationality: this.state.nationallitySearch,
          guestId: this.state.guest_id,
          is_web_app: false,
          vid: this?.props?.query?.vid,
          // sid: this?.props?.query?.sid,
        };
        this.setState({
          btnClicked: true,
        });
        // console.log(urlQuery, "urlQuery")
        if (this.props.isDetail === true) {
          const searchParams = new URLSearchParams(urlQuery);
          const queryString = searchParams.toString();
          window.location = "/hotel/details?" + queryString;
        } else {
          // Recent Search functionaliy
          if (
            this.state.hotelName &&
            this?.props?.userProfile &&
            this?.props?.userProfile?.status
          ) {
            if (true) {
              let data = {
                destination_id: this.state.getDestinationId || "",
                search_type: this.state.getSearchType || "",
                search_text: this.state.hotelName || "",
                property_count: this.state.property_count || "",
                dest_type: {
                  code: this.state.dest_type?.code || "",
                  text: this.state.dest_type?.text || "",
                },
              };
              // console.log(this.state.hotelName, this.state.getDestinationId,this.state.getSearchType,
              //   this.state.property_count,this.state.dest_type);

              getRecentSearchHotel(data).then((res) => {
                // console.log("rrespppppp", res, this.props.getrecentSearchList);
              });
            }
          }
          if (this.props.isSRP) {
            this.props.setOpenMobWid();
          }
          cf.redirectTo("/hotel/search", urlQuery);
        }
      }
    });
  };

  onClickSetHotelName = (element) => {
    this.setState({
      hotelName: element.search_text,
      getDestinationId: element.destination_id,
      getSearchType: element.search_type ? element.search_type : 6,
      property_count: element?.property_count,
      dest_type: {
        code: element?.dest_type?.code,
        text: element?.dest_type?.text,
      },
    });
  };
  getCheckInOutValue = (checkIn, checkOut) => {
    let checkInArr = checkIn.split("/");
    let checkOutArr = checkOut.split("/");
    return checkIn + " - " + checkOut;
  };
  onClickVisibilityStatus = (type, value) => {
    if (!this.props?.isMobile) {
      this.setScrollDown.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    switch (type) {
      case "hotel":
        this.props.expandHotelNameBooleanfunc(value);
        if (
          this.state.expandCheckInOutDateBoolean === true ||
          this.state.openRoomDetailList === true
        ) {
          this.setState({
            expandCheckInOutDateBoolean: false,
            openRoomDetailList: false,
          });
        }
        break;
      case "calendar":
        this.setState({ expandCheckInOutDateBoolean: value }, () => {
          if (
            this.props.expandHotelNameBoolean === true ||
            this.state.openRoomDetailList === true
          ) {
            this.setState({ openRoomDetailList: false });
            this.props.expandHotelNameBooleanfunc(false);
          }
        });
        break;
      case "rooms":
        if (this.state.openRoomDetailList === false) {
          this.setState({ openRoomDetailList: true }, () => {
            if (
              this.props.expandHotelNameBoolean === true ||
              this.state.expandCheckInOutDateBoolean === true
            ) {
              this.setState({ expandCheckInOutDateBoolean: false });
              this.props.expandHotelNameBooleanfunc(false);
            }
          });
        } else {
          this.onClickDoneButton();
        }
        break;
      default:
        break;
    }
  };
  onClickHotelInput = () => {
    this.props.getAutoSuggestValue("");
  };
  renderInfo = () => {
    this?.props?.getAutoSuggestValue(regions);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      regions != null &&
      regions != "" &&
      regions != undefined &&
      !this.props.isSRP
    ) {
      if (
        prevProps?.getAutoSuggestList?.length <
        this?.props?.getAutoSuggestList?.length
      ) {
        prevProps.getAutoSuggestList = this.props.getAutoSuggestList;
        this.setState(
          {
            hotelName: prevProps.getAutoSuggestList[0].search_text,
            getDestinationId: prevProps.getAutoSuggestList[0].destination_id,
            getSearchType: prevProps.getAutoSuggestList[0].search_type,
          },
          () => {
            this.props.expandHotelNameBooleanfunc(false);
          }
        );
      }
    }
  }

  onInputFocus = (fieldName) => {
    if (fieldName == "autocomplete") {
      this.onClickVisibilityStatus("hotel", true);
    }
    if (fieldName == "calendar") {
      this.onClickVisibilityStatus("calendar", true);
      this.setState({ expandCheckInOutDateBoolean: true });
    }
    if (fieldName == "rooms") {
      this.onClickVisibilityStatus("rooms");
      this.setState({
        openRoomDetailList: true,
      });
    }
    if (fieldName == "search") {
      this.setState({ openRoomDetailList: false });
    }
  };

  highlightData(id, parentElement) {
    let innerContent = document.getElementById(parentElement).children;
    for (let i = 0; i < innerContent.length; i++) {
      let selectedElement = innerContent[i];
      selectedElement.style.backgroundColor = i == id ? "#ccc" : "#fff";
    }
    innerContent[id].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }

  onKeyDownArrow = (event, parentClassId, moduleType) => {
    const autoSuggestList = !cf.isValueEmpty(this.props.getAutoSuggestList)
      ? this.props.getAutoSuggestList
      : [];
    const recentSearchLogedin = this.props.getRecentSearchList;
    let recentSearchList = null;
    let mainArray = [];

    if (true) {
      let LocalSearch = getStorage(this.state.search_key);
      let LocalSearchAll = getStorage(this.state.search_key_all);

      if (LocalSearchAll != null) {
        recentSearchList = JSON.parse(LocalSearchAll);
      }
      if (LocalSearch != null && LocalSearchAll == null) {
        recentSearchList = [JSON.parse(LocalSearch)];
      }
    }

    // console.log("recentSearchLogedin",recentSearchLogedin.length);
    if (this.props?.userProfile && this.props?.userProfile?.status) {
      if (recentSearchLogedin.length > 0 && autoSuggestList.length > 0) {
        mainArray = autoSuggestList.concat(recentSearchLogedin);
      } else if (
        recentSearchLogedin.length == 0 &&
        autoSuggestList.length > 0
      ) {
        mainArray = autoSuggestList;
      } else if (
        recentSearchLogedin.length > 0 &&
        autoSuggestList.length == 0
      ) {
        mainArray = recentSearchLogedin;
      } else {
        mainArray = [];
      }
    } else {
      if (recentSearchList?.length > 0 && autoSuggestList?.length > 0) {
        mainArray = autoSuggestList.concat(recentSearchList);
      } else if (recentSearchList == null && autoSuggestList?.length > 0) {
        mainArray = autoSuggestList;
      } else if (recentSearchList?.length > 0 && autoSuggestList?.length == 0) {
        mainArray = recentSearchList;
      } else {
        mainArray = [];
      }
    }

    if (mainArray.length > 0) {
      const ids = mainArray.map(({ destination_id }) => destination_id);
      mainArray = mainArray.filter(
        ({ destination_id }, index) => !ids.includes(destination_id, index + 1)
      );
    }

    this.setState({ isKeyDown: true });

    switch (event.which) {
      case 38: // up
        this.setState({ isKeyDown: true });
        if (mainArray.length > 0) {
          indexDropdown--;
          if (indexDropdown < 0) {
            indexDropdown = 0;
          }
          this.highlightData(indexDropdown, parentClassId);
          search_text =
            mainArray.length >= indexDropdown
              ? `${mainArray[indexDropdown].search_text}`
              : "";
          destination_id =
            mainArray.length >= indexDropdown
              ? `${mainArray[indexDropdown].destination_id}`
              : "";
          search_type =
            mainArray.length >= indexDropdown
              ? `${
                  mainArray[indexDropdown].search_type
                    ? mainArray[indexDropdown].search_type
                    : 1
                }`
              : "";
        }

        break;
      case 40: // down
        this.setState({ isKeyDown: true });
        if (
          mainArray.length > 0 &&
          this.state.isKeyDown &&
          !this.state.isSearchDest
        ) {
          indexDropdown++;
          if (indexDropdown >= mainArray.length - 1) {
            indexDropdown = mainArray.length - 1;
          }
          this.highlightData(indexDropdown, parentClassId);
          search_text =
            mainArray.length >= indexDropdown
              ? `${mainArray[indexDropdown].search_text}`
              : "";
          destination_id =
            mainArray.length >= indexDropdown
              ? `${mainArray[indexDropdown].destination_id}`
              : "";
          search_type =
            mainArray.length >= indexDropdown
              ? `${
                  mainArray[indexDropdown].search_type
                    ? mainArray[indexDropdown].search_type
                    : 1
                }`
              : "";
        }
        if (this.state.isSearchDest) {
          indexDropdown++;
          if (indexDropdown > this.props.getAutoSuggestList.length - 1) {
            indexDropdown = 0;
          }
          this.highlightData(indexDropdown, parentClassId);
          search_text =
            this.props.getAutoSuggestList.length >= indexDropdown
              ? `${this.props.getAutoSuggestList[indexDropdown].search_text}`
              : "";
          destination_id =
            this.props.getAutoSuggestList.length >= indexDropdown
              ? `${this.props.getAutoSuggestList[indexDropdown].destination_id}`
              : "";
          search_type =
            this.props.getAutoSuggestList.length >= indexDropdown
              ? `${
                  this.props.getAutoSuggestList[indexDropdown].search_type
                    ? this.props.getAutoSuggestList[indexDropdown].search_type
                    : 1
                }`
              : "";
        }

        break;

      case 13: //enter
        this.setState(
          {
            hotelName: search_text,
            getDestinationId: destination_id,
            getSearchType: search_type,
            isKeyDown: false,
          },
          () => {
            this.onClickVisibilityStatus("hotel", false);
          }
        );
        indexDropdown = -1;
        break;
      case 9: //tab
        this.setState(
          {
            hotelName: search_text,
            getDestinationId: destination_id,
            getSearchType: search_type,
            isKeyDown: false,
          },
          () => {
            this.onClickVisibilityStatus("hotel", false);
          }
        );
        indexDropdown = -1;

        break;
      default:
    }
  };
  focusOutDone = () => {
    /*this.onClickVisibilityStatus("rooms", false) */
    this.setState({
      openRoomDetailList: this.state.openRoomDetailList,
    });
    // console.log("focus out done");
  };
  onClickTraveller = () => {
    if (!this.state.openTraveler) {
      this.setState({ openTraveler: true });
    } else {
      this.setState({ openTraveler: false });
    }
  };

  onClickAdvSearch = () => {
    if (!this.state.advSearch) {
      this.setState({ advSearch: true });
    } else {
      this.setState({ advSearch: false });
    }
  };

  onClickNationality = () => {
    if (!this.state.nationallitySearch) {
      this.setState({ nationallitySearch: true });
    } else {
      this.setState({ nationallitySearch: false });
    }
  };

  onTickAdvSearch = () => {
    cf.redirectTo("/hotel/search", urlQuery);
  };

  applyb2bFilters = (filterName, event, checkbox, index) => {
    switch (filterName) {
      case "star":
        const { value, checked } = event.target;
        if (checked && !this.state.response.includes(value)) {
          this.setState({ response: [...this.state.response, value] });
        } else {
          this.setState({
            response: this.state.response.filter((val) => {
              return val !== value;
            }),
          });
        }
        break;
      default:
        break;
    }
  };

  handleStarCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  selectNationality = (params) => {
    this.setState({ selectedNationality: params });
    this.setState({ nationallitySearch: params.countryCode });
  };

  onChangeNationalityCode = (params) => {
    this.setState({ selectedNationality: { flag: "/images/ax-tick.svg" } });
  };

  handleCheckboxSelection = (event, index) => {
    let updatedSelections = new Array(index + 1).fill(false);
    updatedSelections[index] = event.target.checked;

    this.setState((prev) => ({
      selectedCheckBoxes: {
        ...prev.selectedCheckBoxes,
        [event.target.name]: updatedSelections,
      },
    }));
  };

  adGuestId = (id) => {
    this.setState({ guest_id: id }, () => {
      if (this.state.isError.selectedGuest === true) {
        this.validateErrorMsg();
      }
      this.setState({ guest_id: id });
    });
  };

  rmGuestId = () => {
    this.setState({ guest_id: "" });
  };

  handleOnClick = async (suggestion, index) => {
    this.setState({ selectedDestination: index });
    await this.onClickSetHotelName(suggestion);
    await this.onClickSearchHotel();
    return suggestion;
  };

  render() {
    return (
      <div
        ref={this.setScrollDown}
        className={`${
          this.props.isMobile ? this.props.styles?.hotel_mobileContainer : ""
        } ${!this.props.isSRP ? "container-1242 mx-auto" : ""}`}
      >
        <div className={this.props.styles?.hotel_search_wid_container}>
          <div className={this.props.styles?.hotel_search_wid_main}>
            {!this.props.isSRP ? (
              <div className={this.props.styles?.hotel_way_title}>
                {/* <h2>Hotels & Accomodation</h2> */}
              </div>
            ) : null}
            {!this.props.isSRP && this.props?.userProfile?.type === "B2B" && (
              <B2BWidgets
                {...this.state}
                styles={this.props.b2bStyles}
                onClickTraveller={this.onClickTraveller}
                onClickAdvSearch={this.onClickAdvSearch}
                onClickSearchHotel={this.onClickSearchHotel}
                onTickAdvSearch={this.onTickAdvSearch}
                onClickNationality={this.onClickNationality}
                applyb2bFilters={this.applyb2bFilters}
                handleCheckboxSelection={this.handleCheckboxSelection}
                selectedCheckBoxes={this.state.selectedCheckBoxes}
                handleStarCheck={this.handleStarCheck}
                isOneStar={this.state.isOneStar}
                isTwoStar={this.state.isTwoStar}
                isThreeStar={this.state.isThreeStar}
                isFourStar={this.state.isFourStar}
                isFiveStar={this.state.isFiveStar}
                addGuestId={this.adGuestId}
                removeGuestId={this.rmGuestId}
                listWrapper={this.advanceSearchWrapper}
                guestWrapper={this.guestDetailWrapper}
                isOffline={this.props.isOffline}
                errorMessage={"Select Traveler"}
                isError={this.state.isError.selectedGuest}
              />
            )}
            <div
              className={`${
                this.props.styles?.hotel_search_details_container
              } ${
                !this.props.isSRP
                  ? this.props.styles?.home_bgcolor
                  : this.props.styles?.srp_bgcolor
              }`}
            >
              <div className={this.props.styles?.hotel_search_details_main}>
                <div
                  className={this.props.styles?.hotel_search_details_row}
                  ref={this.setDropdownWrapperRef}
                >
                  <div
                    id="hotelList"
                    className={`${this.props.styles?.hotel_search_column} ${
                      this.props.styles?.hotel_search_name
                    } ${
                      this.props.isSRP
                        ? this.props.styles?.srp_hotel_search_name
                        : ""
                    }`}
                    // tabIndex="1"
                  >
                    {/* {console.log("this.state.hotelName",this.state.hotelName)} */}

                    {!this.props.isMobile && (
                      <HotelAutoComplete
                        isDisable={this.props.isDisable === true}
                        label={
                          this.props?.isDetail
                            ? "Property Name"
                            : "Destination"
                        }
                        styles={this.props.styles}
                        value={this.state.hotelName}
                        onKeyDownArrow={(event) => {
                          this.onKeyDownArrow(event, "hotelDataArray", "hotel");
                        }}
                        placholder="City, Property Name Or Location"
                        onInputFocus={() => {
                          this.onInputFocus("autocomplete");
                        }}
                        onClickHotelInput={() => this.onClickHotelInput()}
                        onClickHotelName={(value) =>
                          this.onClickSetHotelName(value)
                        }
                        onChangeHotelName={(value) =>
                          this.onChangeSetHotelName(value)
                        }
                        isAutoCompleteExpand={(value) =>
                          this.onClickVisibilityStatus("hotel", value)
                        }
                        autoCompleteData={
                          !cf.isValueEmpty(this.props.getAutoSuggestList)
                            ? this.props.getAutoSuggestList
                            : []
                        }
                        userProfile={this.props.userProfile}
                        getrecentSearchList={
                          this?.props?.userProfile?.status == true
                            ? !cf.isValueEmpty(this.props.getRecentSearchList)
                              ? this.props.getRecentSearchList
                              : []
                            : !cf.isValueEmpty(
                                this.state.getrecentSearchListLocal
                              )
                            ? this.state.getrecentSearchListLocal
                            : this.state.getrecentSearchListLocal
                        }
                        isKeyDown={this.state.isKeyDown}
                        expandAutoCompleteBool={
                          !cf.isValueEmpty(this.props.expandHotelNameBoolean)
                            ? this.props.expandHotelNameBoolean
                            : false
                        }
                        leftIcon={
                          this.props.isSRP ? (
                            ""
                          ) : (
                            <span className={this.props.styles?.label_icon}>
                              <svg
                                version="1.1"
                                id="Layer_1"
                                x="0px"
                                y="0px"
                                width="200px"
                                height="200px"
                                viewBox="0 0 200 200"
                                enableBackground="new 0 0 200 200"
                              >
                                <g>
                                  <path
                                    fill="currentColor"
                                    d="M101,184.511c-0.031,0-0.062,0-0.094-0.001c-1.113-0.026-2.164-0.515-2.902-1.349
                                      c-1.537-1.736-3.586-4.031-6.147-6.885c-2.659-2.961-7.256-8.648-14.055-17.387c-6.738-8.66-12.719-17.192-17.779-25.359
                                      c-5.106-8.243-9.814-17.68-13.991-28.049c-4.284-10.633-6.457-20.179-6.457-28.372c0-16.481,5.985-30.635,17.79-42.068
                                      C69.39,23.395,84.07,17.489,101,17.489s31.546,5.842,43.442,17.365c11.932,11.556,17.982,25.772,17.982,42.255
                                      c0,8.19-2.106,17.607-6.26,27.989c-4.062,10.15-9.045,19.803-14.812,28.688c-5.665,8.729-11.346,17.015-16.885,24.627
                                      c-5.553,7.63-10.354,13.807-14.27,18.359c-0.053,0.062-0.107,0.121-0.164,0.18l-6.167,6.346
                                      C103.114,184.074,102.079,184.511,101,184.511z M101,25.489c-14.988,0-27.441,5.004-38.069,15.298
                                      C52.599,50.795,47.576,62.676,47.576,77.109c0,7.164,1.977,15.704,5.876,25.382c4.007,9.945,8.506,18.972,13.372,26.826
                                      c4.91,7.927,10.729,16.224,17.292,24.66c6.589,8.469,11.196,14.174,13.693,16.955c1.222,1.361,2.329,2.597,3.319,3.706l3.084-3.174
                                      c3.762-4.381,8.399-10.354,13.787-17.758c5.456-7.498,11.056-15.665,16.643-24.275c5.483-8.45,10.226-17.637,14.095-27.305
                                      c3.774-9.433,5.688-17.85,5.688-25.018c0-14.434-5.086-26.376-15.549-36.508C128.378,30.432,115.988,25.489,101,25.489z"
                                  />
                                </g>
                                <g>
                                  <path
                                    fill="currentColor"
                                    d="M101,91.748c-7.077,0-12.834-5.757-12.834-12.834S93.923,66.079,101,66.079s12.835,5.757,12.835,12.834
                                      S108.077,91.748,101,91.748z M101,74.079c-2.666,0-4.834,2.169-4.834,4.834s2.169,4.834,4.834,4.834
                                      c2.666,0,4.835-2.169,4.835-4.834S103.666,74.079,101,74.079z"
                                  />
                                </g>
                              </svg>
                            </span>
                          )
                        }
                        errorMessage={"Enter destination"}
                        isError={this.state.isError.hotelName}
                        disabled={this.props.isDisable === true}
                        selectedHotel={this.props?.selectedHotel}
                        isDetailPage={this.props?.isDetail}
                        isSearchDest={this.state.isSearchDest}
                      />
                    )}
                    {this.props.isMobile && (
                      <div
                        className={this.props.styles?.widget_mainBox}
                        onClick={() => {
                          document.querySelector("body").style.overflow =
                            "hidden";
                          this.setState({
                            destination: true,
                          });
                        }}
                      >
                        <label className={this.props.styles?.widget_label}>
                          Destination
                        </label>

                        <div
                          className={this.props.styles?.widgMob_inp}
                          onClick={() => {
                            document.querySelector("body").style.overflow =
                              "hidden";
                            this.setState({
                              destination: true,
                            });
                          }}
                        >
                          <input
                            value={this.state.hotelName}
                            type="text"
                            placeholder="City, Property Name Or Location"
                            tabIndex="1"
                          />
                        </div>

                        {this.state.destination == false && (
                          <div className={this.props.styles?.error_message}>
                            <span>
                              {this.state.hotelName == "" &&
                              this.state.isError.hotelName
                                ? "Enter destination"
                                : ""}
                              {this.state.hotelName != "" &&
                              this.props.getAutoSuggestList.length == 0
                                ? "Enter valid destination"
                                : ""}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {!this.props.isMobile && (
                    <div className={this.props.styles?.newhoteldate}>
                      <div
                        onFocus={() => {
                          this.onInputFocus("calendar");
                        }}
                        className={this.props.styles?.newlefthoteldate}
                        tabIndex="1"
                      >
                        <DesktopCalender
                          styles={this.props.styles}
                          value={
                            !cf.isValueEmpty(this.state.checkInOutValue)
                              ? this.state.checkInOutValue
                              : ""
                          }
                          onChangeDate={(type, value) => {
                            this.onChangeCheckInOutValue(type, value);
                          }}
                          onInputFocus={() => {
                            this.onInputFocus("calendar");
                          }}
                          startDate={
                            !cf.isValueEmpty(this.state.checkInValue)
                              ? this.state.checkInValue
                              : ""
                          }
                          endDate={
                            !cf.isValueEmpty(this.state.checkOutValue)
                              ? this.state.checkOutValue
                              : ""
                          }
                          startDateLimit={
                            !cf.isValueEmpty(this.state.dateLimit)
                              ? this.state.dateLimit
                              : ""
                          }
                          dateFormat={this?.props?.dateFormat}
                          changeVisibility={(bool) =>
                            this.onClickVisibilityStatus("calendar", bool)
                          }
                          isVisible={this.state.expandCheckInOutDateBoolean}
                          type={"double"}
                          doubleSelection={true}
                          theme={"theme2"}
                        />
                      </div>
                    </div>
                  )}
                  {this.props.isMobile && (
                    <div
                      className={`${this.props.styles?.widget_mainBox} ${this.props.styles?.mobile_widget_container_new}`}
                      onClick={() => {
                        this.setState({
                          // calender: true,
                        });
                      }}
                    >
                      <div className={this.props.styles?.widgMob_Wrapflx}>
                        <div className={this.props.styles?.widgMob_sec}>
                          {/* <label
                            className={this.props.styles?.widget_label}
                            htmlFor=""
                          >
                            Check In
                          </label> */}
                          <MobileCalender
                            // label="Check In & Check Out Date"
                            styles={this.props.styles}
                            value={
                              !commonFunction.isValueEmpty(
                                this.state.checkInOutValue
                              )
                                ? this.state.checkInOutValue
                                : ""
                            }
                            placholder="Select the date"
                            onChangeDate={(type, value) => {
                              this.onChangeCheckInOutValue(type, value);
                            }}
                            iconImage="/images/cyan-calendar.svg"
                            startDate={
                              !commonFunction.isValueEmpty(
                                this.state.checkInValue
                              )
                                ? this.state.checkInValue
                                : ""
                            }
                            endDate={
                              !commonFunction.isValueEmpty(
                                this.state.checkOutValue
                              )
                                ? this.state.checkOutValue
                                : ""
                            }
                            startDateLimit={
                              !commonFunction.isValueEmpty(this.state.dateLimit)
                                ? this.state.dateLimit
                                : ""
                            }
                            dateFormat={"DD-MMM-YYYY"}
                            changeVisibility={(bool) =>
                              this.onClickVisibilityStatus("calendar", bool)
                            }
                            isVisible={this.state.expandCheckInOutDateBoolean}
                            type={"double"}
                            doubleSelection={true}
                            totalMonths={12}
                            serviceType="hotel"
                            isMobile={true}
                            isCheckIn={true}
                            label="Check In"
                          />
                        </div>
                        <span
                          className={this.props.styles?.widgMob_arrow}
                          onClick={() => {
                            this.onInputFocus("calendar");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="11"
                          >
                            <path
                              fill-rule="evenodd"
                              fill="#266AAC"
                              d="m17.373 2.754-7.394 7.457c-.139.14-.3.242-.472.311a1.386 1.386 0 0 1-1.513-.309L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.441 0 1.991z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  )}

                  {this.props.isMobile && (
                    <div
                      className={`${this.props.styles?.widget_mainBox} ${this.props.styles?.mobile_widget_container_new}`}
                      onClick={() => {
                        this.setState({
                          // calender: true,
                        });
                      }}
                    >
                      {/* <div className={this.props.styles?.widgMob_Wrapflx}>
                        <div className={this.props.styles?.widgMob_sec}>
                          <label className={this.props.styles?.widget_label}>Check Out</label>
                          <div className={this.props.styles?.widgMob_dateInp}>{this.state.checkOutValue}</div>
                        </div>
                        <span className={this.props.styles?.widgMob_arrow}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="11">
                              <path fill-rule="evenodd" fill="#266AAC" d="m17.373 2.754-7.394 7.457c-.139.14-.3.242-.472.311a1.386 1.386 0 0 1-1.513-.309L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.441 0 1.991z"/>
                          </svg>
                        </span>
                      </div> */}
                      <div className={this.props.styles?.widgMob_Wrapflx}>
                        <div className={this.props.styles?.widgMob_sec}>
                          {/* <label
                            className={this.props.styles?.widget_label}
                            htmlFor=""
                          >
                            Check Out
                          </label> */}
                          <MobileCalender
                            // label="Check In & Check Out Date"
                            styles={this.props.styles}
                            value={
                              !commonFunction.isValueEmpty(
                                this.state.checkInOutValue
                              )
                                ? this.state.checkInOutValue
                                : ""
                            }
                            placholder="Select the date"
                            onChangeDate={(type, value) => {
                              this.onChangeCheckInOutValue(type, value);
                            }}
                            iconImage="/images/cyan-calendar.svg"
                            startDate={
                              !commonFunction.isValueEmpty(
                                this.state.checkInValue
                              )
                                ? this.state.checkInValue
                                : ""
                            }
                            endDate={
                              !commonFunction.isValueEmpty(
                                this.state.checkOutValue
                              )
                                ? this.state.checkOutValue
                                : ""
                            }
                            startDateLimit={
                              !commonFunction.isValueEmpty(this.state.dateLimit)
                                ? this.state.dateLimit
                                : ""
                            }
                            dateFormat={"DD-MMM-YYYY"}
                            changeVisibility={(bool) =>
                              this.onClickVisibilityStatus("calendar", bool)
                            }
                            isVisible={this.state.expandCheckInOutDateBoolean}
                            type={"double"}
                            doubleSelection={true}
                            totalMonths={12}
                            serviceType="hotel"
                            isMobile={true}
                            // isCheckOut={true}
                            isCheckIn={false}
                            label="Check Out"
                          />
                        </div>
                        <span
                          className={this.props.styles?.widgMob_arrow}
                          onClick={() => {
                            this.onInputFocus("calendar");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="11"
                          >
                            <path
                              fill-rule="evenodd"
                              fill="#266AAC"
                              d="m17.373 2.754-7.394 7.457c-.139.14-.3.242-.472.311a1.386 1.386 0 0 1-1.513-.309L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.441 0 1.991z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  )}
                  {this.props.isMobile && (
                    <div
                      className={this.props.styles?.widget_mainBox}
                      onClick={() => {
                        this.setState({
                          rooms: true,
                        });
                        document.querySelector("body").style.overflow =
                          "hidden";
                      }}
                    >
                      <div className={this.props.styles?.widgMob_Wrapflx}>
                        <div className={this.props.styles?.widgMob_sec}>
                          <label className={this.props.styles?.widget_label}>
                            {this.state.selectedRoomDetails.rooms > 1
                              ? "Rooms"
                              : "Room"}{" "}
                            &
                            {this.state.selectedRoomDetails.guest > 1
                              ? " Guests"
                              : " Guest"}
                          </label>
                          <div className={this.props.styles?.widgMob_dateInp}>
                            <span>
                              {/* {this.state.selectedRoomDetails.rooms} Rooms{" "} */}
                            </span>
                            {this.state.selectedRoomDetails.rooms > 1
                              ? `${this.state.selectedRoomDetails.rooms} Rooms`
                              : `${this.state.selectedRoomDetails.rooms} Room`}
                            , {this.state.selectedRoomDetails.guest}{" "}
                            {this.state.selectedRoomDetails.guest > 1
                              ? " Guests"
                              : " Guest"}
                          </div>
                        </div>
                        <span className={this.props.styles?.widgMob_arrow}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="11"
                          >
                            <path
                              fill-rule="evenodd"
                              fill="#266AAC"
                              d="m17.373 2.754-7.394 7.457c-.139.14-.3.242-.472.311a1.386 1.386 0 0 1-1.513-.309L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.441 0 1.991z"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  )}
                  {this.props.isMobile && (
                    <div className={this.props.styles?.hotel_search_btn_new}>
                      <Button
                        className={
                          this.state?.btnClicked
                            ? `${this.props.styles?.hotel_search_widget_btn} loading`
                            : this.props.styles?.hotel_search_widget_btn
                        }
                        name={this.props.isSRP ? "Search" : "Search"}
                        isHandleOnClick={this.onClickSearchHotel} // Ensure to pass the required prop
                      >
                        {this.props.isSRP ? "Search" : "Search"}
                      </Button>
                    </div>
                  )}
                  {!this.props.isMobile && (
                    <div className={this.props.styles?.hotel_guestCont}>
                      <div
                        onClick={() => this.onClickVisibilityStatus("rooms")}
                        className={this.props.styles?.drop_wrap}
                      >
                        <label
                          className={this.props.styles?.hotel_guestlabel}
                          htmlFor=""
                        >
                          {this.state.selectedRoomDetails.rooms > 1
                            ? "Rooms"
                            : "Room"}{" "}
                          &
                          {this.state.selectedRoomDetails.guest > 1
                            ? " Guests"
                            : " Guest"}
                        </label>
                        {/* this.state.openRoomDetailList === true */}
                        <span
                          class={`${this.props.styles?.hotel_dropdown} 
                        ${
                          this.state.openRoomDetailList === true
                            ? this.props.styles?.arrow_rotate
                            : ""
                        }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="11"
                          >
                            <path
                              fill-rule="evenodd"
                              fill="#266AAC"
                              d="m17.373 2.754-7.394 7.457c-.139.14-.3.243-.472.312a1.388 1.388 0 0 1-1.513-.31L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.442 0 1.991z"
                            />
                          </svg>
                        </span>
                      </div>
                      <div
                        className={
                          this.props.styles?.hotel_search_column +
                          " " +
                          this.props.styles?.hotel_search_accomodation
                        }
                      >
                        <div
                          className={this.props.styles?.hotel_cabin_class_cont}
                          onClick={() => this.onClickVisibilityStatus("rooms")}
                        >
                          {/* <label>Rooms & Guests</label> */}

                          <div
                            className={
                              this.props.styles?.hotel_cabin_class_name
                            }
                          >
                            {/* <input
                          tabIndex="3"
                          onFocus={() => {
                            this.onInputFocus("rooms");
                          }}
                          value={
                            !cf.isValueEmpty(this.state.selectedRoomDetails)
                              ? this.state.selectedRoomDetails.rooms +
                                " Room, " +
                                this.state.selectedRoomDetails.guest +
                                " Guest"
                              : "1 Room, 2 Guest"
                          }
                        /> */}
                            <div
                              tabIndex="1"
                              onFocus={() => {
                                this.onInputFocus("rooms");
                              }}
                              className={this.props.styles?.adult_room_flex}
                            >
                              <span>
                                {this.state.selectedRoomDetails.guest}{" "}
                                {this.state.selectedRoomDetails.guest > 1
                                  ? " Guests"
                                  : " Guest"}
                                {", "}
                                {this.state.selectedRoomDetails.rooms}{" "}
                                {this.state.selectedRoomDetails.rooms > 1
                                  ? "Rooms"
                                  : "Room"}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                        {this.state.openRoomDetailList === true ? (
                          <div className={this.props.styles?.hotel_room_list}>
                            <div
                              className={
                                this.props.styles?.hotel_room_list_main
                              }
                            >
                              {this.state.roomList.map((element, index) => {
                                return (
                                  <div
                                    className={
                                      this.props.styles?.hotel_room_detail_ele
                                    }
                                    key={index}
                                  >
                                    <div
                                      className={
                                        this.props.styles?.hotel_room_head
                                      }
                                    >
                                      <div
                                        className={
                                          this.props.styles?.hotel_room_title
                                        }
                                      >
                                        Room {index + 1}
                                      </div>
                                      {index > 0 ? (
                                        <div
                                          className={
                                            this.props.styles?.hotel_room_remove
                                          }
                                          onClick={() =>
                                            this.removeRoomItem(element.id)
                                          }
                                        >
                                          Remove
                                        </div>
                                      ) : null}
                                    </div>
                                    <div
                                      className={
                                        this.props.styles
                                          ?.hotel_accomodation_main
                                      }
                                    >
                                      <div
                                        className={
                                          this.props.styles
                                            ?.hotel_adult_accomodation
                                        }
                                      >
                                        <div className="hotel_adult_accomodation_tag">
                                          <p>Adult</p>
                                          <span>(18+ yrs)</span>
                                        </div>
                                        <div
                                          className={
                                            this.props.styles
                                              .hotel_accomodation_conf
                                          }
                                        >
                                          <span
                                            className={
                                              element.adultMinusCountDisable
                                                ? this.props.styles
                                                    .disbale_traveller +
                                                  " " +
                                                  this.props.styles
                                                    .hotel_accomodation_icon
                                                : this.props.styles
                                                    .hotel_accomodation_icon
                                            }
                                            onClick={() =>
                                              this.minusAccomodationCount(
                                                element.id,
                                                index,
                                                "adult"
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="26"
                                              height="26"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                fill="#397CC6"
                                                d="M12.1 26.003c-6.282 0-12.104-5.822-12.104-13.004C-.004 5.818 5.818-.004 12.1-.004c8.082 0 13.903 5.822 13.903 13.003-.01 7.178-5.826 12.993-13.903 13.004zm0-24.444c-5.419 0-10.541 5.122-10.541 11.44 0 6.319 5.122 11.441 10.541 11.441 7.219 0 12.341-5.122 12.341-11.441-.006-6.315-5.125-11.434-12.341-11.44zm6.603 12.221H7.297a.78.78 0 1 1 0-1.562h11.406a.781.781 0 0 1 0 1.562z"
                                              />
                                            </svg>
                                          </span>
                                          <div
                                            className={
                                              this.props.styles?.ac_count
                                            }
                                          >
                                            {element.adults}
                                          </div>
                                          <span
                                            className={
                                              element.adultAddCountDisable
                                                ? this.props.styles
                                                    .disbale_traveller +
                                                  " " +
                                                  this.props.styles
                                                    .hotel_accomodation_icon
                                                : this.props.styles
                                                    .hotel_accomodation_icon
                                            }
                                            onClick={() =>
                                              this.addAccomodationCount(
                                                element.id,
                                                index,
                                                "adult"
                                              )
                                            }
                                          >
                                            {/* <img src="/images/plus.png" className={this.this.props.styles?.hotel_accomodation_icon} /> */}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="26"
                                              height="26"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                fill="#397CC6"
                                                d="M12.1 26.003c-6.282 0-12.104-5.822-12.104-13.004C-.004 5.818 5.818-.004 12.1-.004c8.082 0 13.903 5.822 13.903 13.003-.01 7.178-5.826 12.993-13.903 13.004zm0-24.444c-5.419 0-10.541 5.122-10.541 11.44 0 6.319 5.122 11.441 10.541 11.441 7.219 0 12.341-5.122 12.341-11.441-.006-6.315-5.125-11.434-12.341-11.44zm6.603 12.221h-4.922v4.922c0 .432-.349.782-1.681.782.469 0 .119-.35.119-.782V13.78H7.297a.78.78 0 1 1 0-1.562h4.922V7.297c0-.432.35-.782-.119-.782 1.332 0 1.681.35 1.681.782v4.921h4.922a.781.781 0 0 1 0 1.562z"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          this.props.styles
                                            .hotel_children_accomodation
                                        }
                                      >
                                        <div
                                          className={
                                            this.props.styles
                                              .children_accomodationTag
                                          }
                                        >
                                          <p>Children</p>
                                          <span>(1 - 17 yrs)</span>
                                        </div>
                                        <div
                                          className={
                                            this.props.styles
                                              .hotel_accomodation_conf
                                          }
                                        >
                                          <span
                                            className={
                                              element.childrenMinusCountDisable
                                                ? this.props.styles
                                                    .disbale_traveller +
                                                  " " +
                                                  this.props.styles
                                                    .hotel_accomodation_icon
                                                : this.props.styles
                                                    .hotel_accomodation_icon
                                            }
                                            onClick={() =>
                                              this.minusAccomodationCount(
                                                element.id,
                                                index,
                                                "children"
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="26"
                                              height="26"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                fill="#397CC6"
                                                d="M12.1 26.003c-6.282 0-12.104-5.822-12.104-13.004C-.004 5.818 5.818-.004 12.1-.004c8.082 0 13.903 5.822 13.903 13.003-.01 7.178-5.826 12.993-13.903 13.004zm0-24.444c-5.419 0-10.541 5.122-10.541 11.44 0 6.319 5.122 11.441 10.541 11.441 7.219 0 12.341-5.122 12.341-11.441-.006-6.315-5.125-11.434-12.341-11.44zm6.603 12.221H7.297a.78.78 0 1 1 0-1.562h11.406a.781.781 0 0 1 0 1.562z"
                                              />
                                            </svg>
                                          </span>
                                          <div
                                            className={
                                              this.props.styles?.ac_count
                                            }
                                          >
                                            {element.children}
                                          </div>
                                          <span
                                            className={
                                              element.childrenAddCountDisable
                                                ? this.props.styles
                                                    .disbale_traveller +
                                                  " " +
                                                  this.props.styles
                                                    .hotel_accomodation_icon
                                                : this.props.styles
                                                    .hotel_accomodation_icon
                                            }
                                            onClick={() =>
                                              this.addAccomodationCount(
                                                element.id,
                                                index,
                                                "children"
                                              )
                                            }
                                          >
                                            {/* <img src="/images/plus.png" className={this.this.props.styles?.hotel_accomodation_icon} /> */}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="26"
                                              height="26"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                fill="#397CC6"
                                                d="M12.1 26.003c-6.282 0-12.104-5.822-12.104-13.004C-.004 5.818 5.818-.004 12.1-.004c8.082 0 13.903 5.822 13.903 13.003-.01 7.178-5.826 12.993-13.903 13.004zm0-24.444c-5.419 0-10.541 5.122-10.541 11.44 0 6.319 5.122 11.441 10.541 11.441 7.219 0 12.341-5.122 12.341-11.441-.006-6.315-5.125-11.434-12.341-11.44zm6.603 12.221h-4.922v4.922c0 .432-.349.782-1.681.782.469 0 .119-.35.119-.782V13.78H7.297a.78.78 0 1 1 0-1.562h4.922V7.297c0-.432.35-.782-.119-.782 1.332 0 1.681.35 1.681.782v4.921h4.922a.781.781 0 0 1 0 1.562z"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    {element.childDetails.length > 0 &&
                                      element.childDetails.map((item, ind) => {
                                        return (
                                          <div
                                            className={
                                              this.props.styles
                                                ?.child_row_container
                                            }
                                            key={ind}
                                          >
                                            <div
                                              className={
                                                this.props.styles
                                                  ?.child_row_main
                                              }
                                            >
                                              <span>
                                                <label>
                                                  Age of Child {ind + 1}
                                                </label>
                                                <input
                                                  type="text"
                                                  className={
                                                    this.props.styles
                                                      ?.selected_age
                                                  }
                                                  placeholder="Select Age"
                                                  value={item.age}
                                                  onClick={() =>
                                                    this.isChildrenAge(
                                                      index,
                                                      ind,
                                                      "isOpen"
                                                    )
                                                  }
                                                  readOnly
                                                />
                                                {item.isError === true ? (
                                                  <span
                                                    className={
                                                      this.props.styles
                                                        .err_message_text
                                                    }
                                                  >
                                                    Please enter valid age
                                                  </span>
                                                ) : null}
                                                <span
                                                  className={
                                                    item.isOpen === true
                                                      ? this.props.styles
                                                          .child_age_select_arrow
                                                      : this.props.styles
                                                          .child_age_select_arrow_rot
                                                  }
                                                  onClick={() =>
                                                    this.isChildrenAge(
                                                      index,
                                                      ind,
                                                      "isOpen"
                                                    )
                                                  }
                                                >
                                                  <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    x="0px"
                                                    y="0px"
                                                    width="30px"
                                                    height="30px"
                                                    viewBox="0 0 30 30"
                                                    enable-background="new 0 0 30 30"
                                                  >
                                                    <path
                                                      id="XMLID_224_"
                                                      fill="currentColor"
                                                      d="M1.869,11.074l12.576,12.575c0.236,0.236,0.556,0.368,0.889,0.368
	c0.334,0,0.654-0.132,0.889-0.368l12.576-12.575c0.489-0.492,0.489-1.288,0-1.779c-0.492-0.491-1.288-0.491-1.779,0L15.333,20.98
	L3.647,9.295C3.401,9.049,3.08,8.926,2.757,8.926c-0.322,0-0.643,0.123-0.889,0.369C1.377,9.786,1.377,10.582,1.869,11.074z"
                                                    />
                                                  </svg>
                                                </span>
                                              </span>
                                              {item.isOpen === true ? (
                                                <div
                                                  className={
                                                    this.props.styles
                                                      ?.age_list_cont
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      this.props.styles
                                                        .age_list_main
                                                    }
                                                  >
                                                    {childAgeList.map(
                                                      (ele, i) => {
                                                        return (
                                                          <div
                                                            key={i}
                                                            className={
                                                              this.props.styles
                                                                .age_list_ele
                                                            }
                                                            onClick={() =>
                                                              this.isChildrenAge(
                                                                index,
                                                                ind,
                                                                "clickAge",
                                                                ele
                                                              )
                                                            }
                                                          >
                                                            <span>{ele}</span>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              })}
                              {this.state.roomList.length < 4 ? (
                                <div
                                  className={this.props.styles?.hotel_add_rooms}
                                  onClick={() => {
                                    this.setState({
                                      roomList: [
                                        ...this.state.roomList,
                                        {
                                          id: this.state.roomList.length + 1,
                                          adults: 1,
                                          children: 0,
                                          adultAddCountDisable: false,
                                          adultMinusCountDisable: true,
                                          childrenAddCountDisable: false,
                                          childrenMinusCountDisable: true,
                                          childDetails: [],
                                        },
                                      ],
                                    });
                                  }}
                                >
                                  <div
                                    className={this.props.styles?.paxPlus_icon}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="26px"
                                      height="26px"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        fill="rgb(57, 124, 198)"
                                        d="M12.1000,26.003 C5.818,26.003 -0.004,20.181 -0.004,12.999 C-0.004,5.818 5.818,-0.004 12.1000,-0.004 C20.181,-0.004 26.003,5.818 26.003,12.999 C25.993,20.177 20.177,25.992 12.1000,26.003 ZM12.1000,1.558 C6.681,1.558 1.559,6.681 1.559,12.999 C1.559,19.318 6.681,24.440 12.1000,24.440 C19.319,24.440 24.441,19.318 24.441,12.999 C24.435,6.684 19.316,1.565 12.1000,1.558 ZM18.703,13.780 L13.781,13.780 L13.781,18.702 C13.781,19.134 13.431,19.483 12.1000,19.483 C12.569,19.483 12.219,19.134 12.219,18.702 L12.219,13.780 L7.297,13.780 C6.866,13.780 6.516,13.431 6.516,12.999 C6.516,12.568 6.866,12.218 7.297,12.218 L12.219,12.218 L12.219,7.297 C12.219,6.865 12.569,6.515 12.1000,6.515 C13.431,6.515 13.781,6.865 13.781,7.297 L13.781,12.218 L18.703,12.218 C19.134,12.218 19.484,12.568 19.484,12.999 C19.484,13.431 19.134,13.780 18.703,13.780 Z"
                                      />
                                    </svg>
                                  </div>
                                  {/* <span> + </span> */}
                                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26">
                                  <path fill-rule="evenodd" fill="#397CC6" d="M12.1 26.003c-6.282 0-12.104-5.822-12.104-13.004C-.004 5.818 5.818-.004 12.1-.004c8.082 0 13.903 5.822 13.903 13.003-.01 7.178-5.826 12.993-13.903 13.004zm0-24.444c-5.419 0-10.541 5.122-10.541 11.44 0 6.319 5.122 11.441 10.541 11.441 7.219 0 12.341-5.122 12.341-11.441-.006-6.315-5.125-11.434-12.341-11.44zm6.603 12.221h-4.922v4.922c0 .432-.349.782-1.681.782.469 0 .119-.35.119-.782V13.78H7.297a.78.78 0 1 1 0-1.562h4.922V7.297c0-.432.35-.782-.119-.782 1.332 0 1.681.35 1.681.782v4.921h4.922a.781.781 0 0 1 0 1.562z"/>
                              </svg> */}
                                  <p>Add Room </p>
                                </div>
                              ) : null}
                            </div>
                            <div
                              className={this.props.styles?.done_button_main}
                            >
                              <input
                                // tabIndex="1"
                                value="Apply"
                                className={this.props.styles?.done_button}
                                readOnly
                                onClick={() => this.onClickDoneButton()}
                                onBlur={this.focusOutDone}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}

                  {!this.props.isMobile && (
                    <div className={this.props.styles?.hotel_search_btn_new}>
                      <Button
                        className={
                          this.state?.btnClicked
                            ? `${this.props.styles?.hotel_search_widget_btn} loading`
                            : this.props.styles?.hotel_search_widget_btn
                        }
                        name={
                          this.props.isSRP ? "Hotels Search" : "Hotels Search"
                        }
                        isHandleOnClick={this.onClickSearchHotel} // Ensure to pass the required prop
                      >
                        {this.props.isSRP ? "Hotels Search" : "Hotels Search"}
                      </Button>
                    </div>
                  )}
                </div>
                <div
                  className={`${this.props.styles?.hotel_search_details_row}`}
                >
                  {!this.props.isSRP &&
                    this.props?.userProfile?.type === "B2B" && (
                      <div className={this.props.styles?.nationality_cnt_wd}>
                        <div className={this.props.styles?.nationality_wrap}>
                          {/* <label htmlFor="fname" className="lablename-htl">Select Nationality</label> */}
                          {/* <input type="text" className={this.props.styles?.input_nationality_wd} name="fname" id="nationallity" placeholder="Select Nationality" onkeyup={{}} autoComplete="off" />
                                                <span className={this.props.styles?.pg_down_arrow}> <i className="fa fa-caret-down" onClick={() => this.onClickNationality()} aria-hidden="true" /></span> */}
                          {/* <CountryDropdownComp
                                                    countryFlag={this.state.originCountries}
                                                    searchText={""}
                                                    selectCountry={this.onClickNationalityHandler}
                                                    selectedCountry={this.state.selectedNationality}
                                                    onChangeInputCountryCode={this.onChangeInputNationality}
                                                    placeholder={'Select Nationality'}
                                                    isSignUpNationality={false}
                                                    isB2B={true}
                                                    {...this.props.Countrystyles}
                                                // {...this.props.data.Countrystyles}
                                                /> */}
                          <NationalityDropdownComp
                            countryFlag={this.state.originCountries}
                            searchText={""}
                            selectNationality={this.selectNationality}
                            selectedNationality={this.state.selectedNationality}
                            onChangeNationalityCode={
                              this.onChangeNationalityCode
                            }
                            placeholder={"Select Nationality"}
                            isSignUpNationality={false}
                            isB2B={true}
                            errorMessage={"Select Nationality"}
                            isError={this.state.isError.selectedNationality}
                            styles={this.props.styles}
                            {...this.props.Countrystyles}
                            // {...this.props.data.Countrystyles}
                          />
                        </div>
                        {/* {this.state.nationallitySearch &&

                                                <div id="myNation" className="dropdown-content-Guest dropdown-content-nation dropdown-content-nation-htl">
                                                    <div className={this.props.styles?.inputsearch_result}><input type="text" placeholder="Search.." id="myInputNation" onkeyup={{}} />
                                                    </div>
                                                    <div id="suggested_nation" />
                                                </div>
                                            } */}
                      </div>
                    )}
                </div>
              </div>
            </div>

            {!this.props.isMobile && this.props.getAutoSuggestList && (
              <div className={this.props.styles?.popular_destination}>
                <div className={this.props.styles?.populardest_title}>
                  Popular Destination
                </div>
                <div className={this.props.styles?.destination_container}>
                  {this.props.homeData.map((sugesstion, index) => {
                    let popDestination = sugesstion.search_text.split(",");
                    return (
                      <div
                        key={index}
                        className={
                          index === this.state.selectedDestination
                            ? this.props.styles?.destination_active
                            : this.props.styles?.destination_item
                        }
                        onClick={() => this.handleOnClick(sugesstion, index)}
                      >
                        <div className={this.props.styles?.destination_name}>
                          {popDestination[0]}
                        </div>
                        <div className={this.props.styles?.property_count}>
                          {sugesstion.property_count} Properties
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* For Destination Popup ===> */}

        <MobPopupComponent
          theme="theme5"
          isPopup={this.state.destination}
          modalCss={this.props?.PopupMobCss}
          closePopup={() => {
            this.setState({ destination: false }, () => {});
            // window.scrollTo({ top: 0, behavior: 'smooth' });
            document.querySelector("body").style.overflow = "auto";
          }}
          showBtn={false}
          heading={"Destination"}
        >
          <div>
            <HotelAutoComplete
              isDisable={this.props.isDisable === true}
              label="City, Property Name Or Location"
              styles={this.props.styles}
              value={this.state.hotelName}
              userProfile={this.props.userProfile}
              getrecentSearchList={
                this?.props?.userProfile?.status
                  ? !cf.isValueEmpty(this.props.getRecentSearchList)
                    ? this.props.getRecentSearchList
                    : []
                  : !cf.isValueEmpty(this.state.getrecentSearchListLocal)
                  ? this.state.getrecentSearchListLocal
                  : this.state.getrecentSearchListLocal
              }
              onKeyDownArrow={(event) => {
                this.onKeyDownArrow(event, "hotelDataArray", "hotel");
              }}
              placholder="City, Property Name Or Location"
              onInputFocus={() => {
                this.onInputFocus("autocomplete");
              }}
              onClickHotelInput={() => this.onClickHotelInput()}
              onClickHotelName={(value) => {
                this.onClickSetHotelName(value);
                this.setState({ destination: false });
                // window.scrollTo({ top: 0, behavior: "smooth" });
                document.querySelector("body").style.overflow = "auto";
              }}
              onChangeHotelName={(value) => this.onChangeSetHotelName(value)}
              isAutoCompleteExpand={(value) =>
                this.onClickVisibilityStatus("hotel", value)
              }
              autoCompleteData={
                !cf.isValueEmpty(this.props.getAutoSuggestList)
                  ? this.props.getAutoSuggestList
                  : []
              }
              expandAutoCompleteBool={
                !cf.isValueEmpty(this.props.expandHotelNameBoolean)
                  ? this.props.expandHotelNameBoolean
                  : false
              }
              leftIcon={
                this.props.isSRP ? (
                  ""
                ) : (
                  <span className={this.props.styles?.label_icon}>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      x="0px"
                      y="0px"
                      width="200px"
                      height="200px"
                      viewBox="0 0 200 200"
                      enableBackground="new 0 0 200 200"
                    >
                      <g>
                        <path
                          fill="currentColor"
                          d="M101,184.511c-0.031,0-0.062,0-0.094-0.001c-1.113-0.026-2.164-0.515-2.902-1.349
		c-1.537-1.736-3.586-4.031-6.147-6.885c-2.659-2.961-7.256-8.648-14.055-17.387c-6.738-8.66-12.719-17.192-17.779-25.359
		c-5.106-8.243-9.814-17.68-13.991-28.049c-4.284-10.633-6.457-20.179-6.457-28.372c0-16.481,5.985-30.635,17.79-42.068
		C69.39,23.395,84.07,17.489,101,17.489s31.546,5.842,43.442,17.365c11.932,11.556,17.982,25.772,17.982,42.255
		c0,8.19-2.106,17.607-6.26,27.989c-4.062,10.15-9.045,19.803-14.812,28.688c-5.665,8.729-11.346,17.015-16.885,24.627
		c-5.553,7.63-10.354,13.807-14.27,18.359c-0.053,0.062-0.107,0.121-0.164,0.18l-6.167,6.346
		C103.114,184.074,102.079,184.511,101,184.511z M101,25.489c-14.988,0-27.441,5.004-38.069,15.298
		C52.599,50.795,47.576,62.676,47.576,77.109c0,7.164,1.977,15.704,5.876,25.382c4.007,9.945,8.506,18.972,13.372,26.826
		c4.91,7.927,10.729,16.224,17.292,24.66c6.589,8.469,11.196,14.174,13.693,16.955c1.222,1.361,2.329,2.597,3.319,3.706l3.084-3.174
		c3.762-4.381,8.399-10.354,13.787-17.758c5.456-7.498,11.056-15.665,16.643-24.275c5.483-8.45,10.226-17.637,14.095-27.305
		c3.774-9.433,5.688-17.85,5.688-25.018c0-14.434-5.086-26.376-15.549-36.508C128.378,30.432,115.988,25.489,101,25.489z"
                        />
                      </g>
                      <g>
                        <path
                          fill="currentColor"
                          d="M101,91.748c-7.077,0-12.834-5.757-12.834-12.834S93.923,66.079,101,66.079s12.835,5.757,12.835,12.834
		S108.077,91.748,101,91.748z M101,74.079c-2.666,0-4.834,2.169-4.834,4.834s2.169,4.834,4.834,4.834
		c2.666,0,4.835-2.169,4.835-4.834S103.666,74.079,101,74.079z"
                        />
                      </g>
                    </svg>
                  </span>
                )
              }
              // errorMessage={"Enter Destination"}
              // isError={this.state.isError.hotelName}
              disabled={this.props.isDisable === true}
              isMobile={true}
            />
            {this.props.getAutoSuggestList && (
              <div className={this.props.styles?.popular_destination}>
                <div className={this.props.styles?.populardest_title}>
                  Suggestions / Popular Destination
                </div>
                <div className={this.props.styles?.destination_container}>
                  {this.props.homeData.map((sugesstion, index) => {
                    let popDestination = sugesstion.search_text.split(",");
                    return (
                      <div
                        className={this.props.styles?.destination_active}
                        onClick={() => {
                          this.onClickSetHotelName(sugesstion);
                          this.handleOnClick(sugesstion, index);
                          this.onClickSearchHotel();
                          this.setState({ destination: false });
                          // window.scrollTo({ top: 0, behavior: 'smooth' })
                          document.querySelector("body").style.overflow =
                            "auto";
                        }}
                      >
                        <div className={this.props.styles?.destination_name}>
                          {popDestination[0]}
                        </div>
                        {/* <div className={this.props.styles?.property_count}>
                        {sugesstion.property_count} Properties
                        </div> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </MobPopupComponent>

        {/* For Calender Popup ===> */}

        <MobPopupComponent
          theme="theme5"
          isPopup={this.state.calender}
          modalCss={this.props?.PopupMobCss}
          closePopup={() => {
            this.setState({ calender: false });
            document.querySelector("body").style.overflow = "auto";
          }}
          showBtn={false}
          heading={"Select Date"}
        >
          <div>
            <MobileCalender
              // label="Check In & Check Out Date"
              styles={this.props.styles}
              value={
                !commonFunction.isValueEmpty(this.state.checkInOutValue)
                  ? this.state.checkInOutValue
                  : ""
              }
              placholder="Select the date"
              onChangeDate={(type, value) => {
                this.onChangeCheckInOutValue(type, value);
              }}
              iconImage="/images/cyan-calendar.svg"
              startDate={
                !commonFunction.isValueEmpty(this.state.checkInValue)
                  ? this.state.checkInValue
                  : ""
              }
              endDate={
                !commonFunction.isValueEmpty(this.state.checkOutValue)
                  ? this.state.checkOutValue
                  : ""
              }
              startDateLimit={
                !commonFunction.isValueEmpty(this.state.dateLimit)
                  ? this.state.dateLimit
                  : ""
              }
              dateFormat={"DD-MMM-YYYY"}
              changeVisibility={(bool) =>
                this.onClickVisibilityStatus("calendar", bool)
              }
              isVisible={this.state.expandCheckInOutDateBoolean}
              type={"double"}
              doubleSelection={true}
              totalMonths={12}
              serviceType="hotel"
              isMobile={true}
            />
          </div>
        </MobPopupComponent>

        {/* For rooms & guest Popup ===> */}
        <MobPopupComponent
          theme="theme5"
          isPopup={this.state.rooms}
          modalCss={this.props?.PopupMobCss}
          closePopup={() => {
            this.setState({ rooms: false });
            document.querySelector("body").style.overflow = "auto";
          }}
          showBtn={false}
          heading={"Room and Guest"}
        >
          <div>
            {true ? (
              <div className={this.props.styles?.hotel_room_list}>
                <div className={this.props.styles?.hotel_room_list_main}>
                  {this.state.roomList.map((element, index) => {
                    return (
                      <div
                        className={this.props.styles?.hotel_room_detail_ele}
                        key={index}
                      >
                        <div className={this.props.styles?.hotel_room_head}>
                          <div className={this.props.styles?.hotel_room_title}>
                            Room {index + 1}
                          </div>
                          {index > 0 ? (
                            <div
                              className={this.props.styles?.hotel_room_remove}
                              onClick={() => this.removeRoomItem(element.id)}
                            >
                              Remove
                            </div>
                          ) : null}
                        </div>
                        <div
                          className={this.props.styles?.hotel_accomodation_main}
                        >
                          <div
                            className={
                              this.props.styles?.hotel_adult_accomodation
                            }
                          >
                            <div className="hotel_adult_accomodation_tag">
                              <p>Adults</p>
                              <span>(18+ yrs)</span>
                            </div>
                            <div
                              className={
                                this.props.styles.hotel_accomodation_conf
                              }
                            >
                              <span
                                className={
                                  element.adultMinusCountDisable
                                    ? this.props.styles.disbale_traveller +
                                      " " +
                                      this.props.styles.hotel_accomodation_icon
                                    : this.props.styles.hotel_accomodation_icon
                                }
                                onClick={() =>
                                  this.minusAccomodationCount(
                                    element.id,
                                    index,
                                    "adult"
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="31"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    fill="#266AAC"
                                    d="M15.013 30.144C6.728 30.144.011 23.418.011 15.122S6.728.101 15.013.101c8.286 0 15.002 6.725 15.002 15.021-.012 8.291-6.722 15.009-15.002 15.022zm0-28.238c-7.29 0-13.199 5.917-13.199 13.216 0 7.299 5.909 13.217 13.199 13.217s13.199-5.918 13.199-13.217C28.205 7.826 22.3 1.913 15.013 1.906zm6.579 14.119H8.434a.902.902 0 0 1 0-1.805h13.158a.902.902 0 0 1 0 1.805z"
                                  />
                                </svg>
                              </span>
                              <div className={this.props.styles?.ac_count}>
                                {`0${element.adults}`}
                              </div>
                              <span
                                className={
                                  element.adultAddCountDisable
                                    ? this.props.styles.disbale_traveller +
                                      " " +
                                      this.props.styles.hotel_accomodation_icon
                                    : this.props.styles.hotel_accomodation_icon
                                }
                                onClick={() =>
                                  this.addAccomodationCount(
                                    element.id,
                                    index,
                                    "adult"
                                  )
                                }
                              >
                                {/* <img src="/images/plus.png" className={this.this.props.styles?.hotel_accomodation_icon} /> */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="31"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    fill="#266AAC"
                                    d="M15.002 30.144C6.717 30.144 0 23.418 0 15.122S6.717.101 15.002.101s15.002 6.725 15.002 15.021c-.012 8.291-6.722 15.009-15.002 15.022zm0-28.238c-7.29 0-13.199 5.917-13.199 13.216 0 7.299 5.909 13.217 13.199 13.217s13.199-5.918 13.199-13.217c-.007-7.296-5.912-13.209-13.199-13.216zm6.579 14.119h-5.678v5.685a.902.902 0 1 1-1.802 0v-5.685H8.423a.902.902 0 0 1 0-1.805h5.678V8.534a.901.901 0 1 1 1.802 0v5.686h5.678a.902.902 0 0 1 0 1.805z"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <div
                            className={
                              this.props.styles.hotel_children_accomodation
                            }
                          >
                            <div
                              className={
                                this.props.styles.children_accomodationTag
                              }
                            >
                              <p>Children</p>
                              <span>(1-17 yrs)</span>
                            </div>
                            <div
                              className={
                                this.props.styles.hotel_accomodation_conf
                              }
                            >
                              <span
                                className={
                                  element.childrenMinusCountDisable
                                    ? this.props.styles.disbale_traveller +
                                      " " +
                                      this.props.styles.hotel_accomodation_icon
                                    : this.props.styles.hotel_accomodation_icon
                                }
                                onClick={() =>
                                  this.minusAccomodationCount(
                                    element.id,
                                    index,
                                    "children"
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="31"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    fill="#266AAC"
                                    d="M15.013 30.144C6.728 30.144.011 23.418.011 15.122S6.728.101 15.013.101c8.286 0 15.002 6.725 15.002 15.021-.012 8.291-6.722 15.009-15.002 15.022zm0-28.238c-7.29 0-13.199 5.917-13.199 13.216 0 7.299 5.909 13.217 13.199 13.217s13.199-5.918 13.199-13.217C28.205 7.826 22.3 1.913 15.013 1.906zm6.579 14.119H8.434a.902.902 0 0 1 0-1.805h13.158a.902.902 0 0 1 0 1.805z"
                                  />
                                </svg>
                              </span>
                              <div className={this.props.styles?.ac_count}>
                                {`0${element.children}`}
                              </div>
                              <span
                                className={
                                  element.childrenAddCountDisable
                                    ? this.props.styles.disbale_traveller +
                                      " " +
                                      this.props.styles.hotel_accomodation_icon
                                    : this.props.styles.hotel_accomodation_icon
                                }
                                onClick={() =>
                                  this.addAccomodationCount(
                                    element.id,
                                    index,
                                    "children"
                                  )
                                }
                              >
                                {/* <img src="/images/plus.png" className={this.this.props.styles?.hotel_accomodation_icon} /> */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="31"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    fill="#266AAC"
                                    d="M15.002 30.144C6.717 30.144 0 23.418 0 15.122S6.717.101 15.002.101s15.002 6.725 15.002 15.021c-.012 8.291-6.722 15.009-15.002 15.022zm0-28.238c-7.29 0-13.199 5.917-13.199 13.216 0 7.299 5.909 13.217 13.199 13.217s13.199-5.918 13.199-13.217c-.007-7.296-5.912-13.209-13.199-13.216zm6.579 14.119h-5.678v5.685a.902.902 0 1 1-1.802 0v-5.685H8.423a.902.902 0 0 1 0-1.805h5.678V8.534a.901.901 0 1 1 1.802 0v5.686h5.678a.902.902 0 0 1 0 1.805z"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                        {element.childDetails.length > 0 &&
                          element.childDetails.map((item, ind) => {
                            return (
                              <div
                                className={
                                  this.props.styles?.child_row_container
                                }
                                key={ind}
                              >
                                <div
                                  className={this.props.styles?.child_row_main}
                                >
                                  <span>
                                    <label>Age of Child {ind + 1}</label>
                                    <input
                                      type="text"
                                      className={
                                        this.props.styles?.selected_age
                                      }
                                      placeholder="Select Age"
                                      value={item.age}
                                      onClick={() =>
                                        this.isChildrenAge(index, ind, "isOpen")
                                      }
                                      readOnly
                                    />
                                    {item.isError === true ? (
                                      <span
                                        className={
                                          this.props.styles.err_message_text
                                        }
                                      >
                                        Please enter valid age
                                      </span>
                                    ) : null}
                                    <span
                                      className={
                                        item.isOpen === true
                                          ? this.props.styles
                                              .child_age_select_arrow
                                          : this.props.styles
                                              .child_age_select_arrow_rot
                                      }
                                      onClick={() =>
                                        this.isChildrenAge(index, ind, "isOpen")
                                      }
                                    >
                                      <svg
                                        version="1.1"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        width="30px"
                                        height="30px"
                                        viewBox="0 0 30 30"
                                        enable-background="new 0 0 30 30"
                                      >
                                        <path
                                          id="XMLID_224_"
                                          fill="currentColor"
                                          d="M1.869,11.074l12.576,12.575c0.236,0.236,0.556,0.368,0.889,0.368
	c0.334,0,0.654-0.132,0.889-0.368l12.576-12.575c0.489-0.492,0.489-1.288,0-1.779c-0.492-0.491-1.288-0.491-1.779,0L15.333,20.98
	L3.647,9.295C3.401,9.049,3.08,8.926,2.757,8.926c-0.322,0-0.643,0.123-0.889,0.369C1.377,9.786,1.377,10.582,1.869,11.074z"
                                        />
                                      </svg>
                                    </span>
                                  </span>
                                  {item.isOpen === true ? (
                                    <div
                                      className={
                                        this.props.styles?.age_list_cont
                                      }
                                    >
                                      <div
                                        className={
                                          this.props.styles.age_list_main
                                        }
                                      >
                                        {childAgeList.map((ele, i) => {
                                          return (
                                            <div
                                              key={i}
                                              className={
                                                this.props.styles.age_list_ele
                                              }
                                              onClick={() =>
                                                this.isChildrenAge(
                                                  index,
                                                  ind,
                                                  "clickAge",
                                                  ele
                                                )
                                              }
                                            >
                                              <span>{ele} Year old</span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                  {this.state.roomList.length < 4 ? (
                    <div
                      className={this.props.styles?.hotel_add_rooms}
                      onClick={() => {
                        this.setState({
                          roomList: [
                            ...this.state.roomList,
                            {
                              id: this.state.roomList.length + 1,
                              adults: 1,
                              children: 0,
                              adultAddCountDisable: false,
                              adultMinusCountDisable: true,
                              childrenAddCountDisable: false,
                              childrenMinusCountDisable: true,
                              childDetails: [],
                            },
                          ],
                        });
                      }}
                    >
                      <div className={this.props.styles?.paxPlus_icon}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26px"
                          height="26px"
                        >
                          <path
                            fill-rule="evenodd"
                            fill="rgb(57, 124, 198)"
                            d="M12.1000,26.003 C5.818,26.003 -0.004,20.181 -0.004,12.999 C-0.004,5.818 5.818,-0.004 12.1000,-0.004 C20.181,-0.004 26.003,5.818 26.003,12.999 C25.993,20.177 20.177,25.992 12.1000,26.003 ZM12.1000,1.558 C6.681,1.558 1.559,6.681 1.559,12.999 C1.559,19.318 6.681,24.440 12.1000,24.440 C19.319,24.440 24.441,19.318 24.441,12.999 C24.435,6.684 19.316,1.565 12.1000,1.558 ZM18.703,13.780 L13.781,13.780 L13.781,18.702 C13.781,19.134 13.431,19.483 12.1000,19.483 C12.569,19.483 12.219,19.134 12.219,18.702 L12.219,13.780 L7.297,13.780 C6.866,13.780 6.516,13.431 6.516,12.999 C6.516,12.568 6.866,12.218 7.297,12.218 L12.219,12.218 L12.219,7.297 C12.219,6.865 12.569,6.515 12.1000,6.515 C13.431,6.515 13.781,6.865 13.781,7.297 L13.781,12.218 L18.703,12.218 C19.134,12.218 19.484,12.568 19.484,12.999 C19.484,13.431 19.134,13.780 18.703,13.780 Z"
                          />
                        </svg>
                      </div>
                      {/* <span> + </span> */}
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26">
                                  <path fill-rule="evenodd" fill="#397CC6" d="M12.1 26.003c-6.282 0-12.104-5.822-12.104-13.004C-.004 5.818 5.818-.004 12.1-.004c8.082 0 13.903 5.822 13.903 13.003-.01 7.178-5.826 12.993-13.903 13.004zm0-24.444c-5.419 0-10.541 5.122-10.541 11.44 0 6.319 5.122 11.441 10.541 11.441 7.219 0 12.341-5.122 12.341-11.441-.006-6.315-5.125-11.434-12.341-11.44zm6.603 12.221h-4.922v4.922c0 .432-.349.782-1.681.782.469 0 .119-.35.119-.782V13.78H7.297a.78.78 0 1 1 0-1.562h4.922V7.297c0-.432.35-.782-.119-.782 1.332 0 1.681.35 1.681.782v4.921h4.922a.781.781 0 0 1 0 1.562z"/>
                              </svg> */}
                      <p>Add Room </p>
                    </div>
                  ) : null}
                </div>
                <div className={this.props.styles?.done_button_main}>
                  <input
                    tabIndex="1"
                    value="Apply Now"
                    className={this.props.styles?.done_button}
                    readOnly
                    onClick={() => {
                      this.onClickDoneButton();
                    }}
                    onBlur={this.focusOutDone}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </MobPopupComponent>
      </div>
    );
  }
}
